<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="24" :xs="24" class="elel">
						<el-button type="primary" @click="dialogAdd = true">新增</el-button>
						<div class="readbox">
							<el-tag size="large">查询是否可用：</el-tag>
							<el-radio-group v-model="status" @change="radioes">
								<el-radio label="" border>全部</el-radio>
								<el-radio :label="0" border>是</el-radio>
								<el-radio :label="1" border>否</el-radio>
							</el-radio-group>
						</div>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column prop="minEcpm" label="ecpm最小值" width="150" align="center"></el-table-column>
					<el-table-column prop="maxEcpm" label="ecpm最大值" width="150" align="center"></el-table-column>
					<el-table-column prop="scaleList" label="比例参数列表"></el-table-column>
					<el-table-column label="是否可用" width="150" align="center">
						<template v-slot="scope">
							<el-switch @change="switchClick(scope.row)" v-model="scope.row.disabled" class="mb-2" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
								:active-value="0" :inactive-value="1" active-text="是" inactive-text="否" />
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间" width="200" align="center"></el-table-column>
					<el-table-column prop="upgradeTime" label="更新时间" width="200" align="center"></el-table-column>
					<el-table-column label="操作" align="center" width="100">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" background
						layout="total, sizes, prev, pager, next, jumper" :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
				<!-- 新增-配置 -->
				<el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="150px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="ECPM区间最小值" prop="minEcpm">
									<el-input-number v-model="ruleForm.minEcpm" :precision="2" :step="0.1" :min="0" placeholder="请输入ECPM区间最小值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="ECPM区间最大值" prop="maxEcpm">
									<el-input-number v-model="ruleForm.maxEcpm" :precision="2" :step="0.1" :min="0" placeholder="请输入ECPM区间最大值" />
								</el-form-item>
							</el-col>
							<el-col :span="24" :xs="24">
								<el-form-item label="比例参数列表" prop="scaleList">
									<el-input :rows="4" type="textarea" v-model="ruleForm.scaleList" placeholder="请输入比例参数列表,多个以英文逗号分割" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改-配置 -->
				<el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="150px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="ECPM区间最小值" prop="minEcpm">
									<el-input-number v-model="EditruleForm.minEcpm" :precision="2" :step="0.1" :min="0" placeholder="请输入ECPM区间最小值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="ECPM区间最大值" prop="maxEcpm">
									<el-input-number v-model="EditruleForm.maxEcpm" :precision="2" :step="0.1" :min="0" placeholder="请输入ECPM区间最大值" />
								</el-form-item>
							</el-col>
							<el-col :span="24" :xs="24">
								<el-form-item label="比例参数列表" prop="scaleList">
									<el-input :rows="4" type="textarea" v-model="EditruleForm.scaleList" placeholder="请输入比例参数列表,多个以英文逗号分割" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
	export default {
		name: 'diamondRewards',
		data() {
			return {
				fullscreenshow: false,
				emptyText: '暂无数据',
				currentPage: 1,
				pageSize: 10,
				tableData: [],
				totals: null,
				serloading: false,
				productId: '',
				status: '',
				// 新增配置
				dialogAdd: false,
				ruleForm: {
					scaleList: null,
					minEcpm: null,
					maxEcpm: null
				},
				rules: {
					minEcpm: [{
						required: true,
						message: '请输入ECPM区间最小值',
						trigger: 'blur'
					}],
					maxEcpm: [{
						required: true,
						message: '请输入ECPM区间最大值',
						trigger: 'blur'
					}],
					scaleList: [{
						required: true,
						message: '请输入比例参数列表',
						trigger: 'blur'
					}]
				},
				// 编辑
				dialogEdit: false,
				EditruleForm: {
					id: '',
					scaleList: '',
					minEcpm: '',
					maxEcpm: ''
				}
			};
		},
		created() {
			if (document.body.clientWidth < 768) {
				this.fullscreenshow = true;
			} else {
				this.fullscreenshow = false;
			}

			this.productId = this.$TOOL.data.get('DATA_SELECTEED')
			// 获取配置
			this.SignInConfig();
		},
		watch: {
			'$store.state.dataSelected'(newval) {
				if (this.productId != newval) {
					this.productId = newval
					// 获取配置
					this.SignInConfig();
				}
			}
		},
		methods: {
			// 获取配置
			SignInConfig() {
				this.serloading = true;
				this.$HTTP.post(
					'riddle/rewardEcpmConfig', {
						disabled: this.status,
						productId: this.productId,
						pageNumber: this.currentPage,
						pageSize: this.pageSize
					},
					response => {
						if (response.status >= 200 && response.status < 300) {
							if (response.data.code == 0) {
								this.tableData = response.data.data.records;
								this.totals = response.data.data.total;
								this.serloading = false;
							} else {
								this.$message.error(response.data.msg);
							}
						} else {
							console.log(response.message);
						}
					}
				);
			},
			// 重置
			resetClick(name) {
				this.$refs[name].resetFields();
			},
			//查新是否可用
			radioes() {
				this.currentPage = 1;
				this.SignInConfig();
			},
			// 是否可用
			switchClick(row) {
				this.$HTTP.post(
					'riddle/ecpmScaleStatus', {
						id: row.id,
						disabled: row.disabled,
						productId: this.productId,
					},
					response => {
						if (response.status >= 200 && response.status < 300) {
							if (response.data.code == 0) {
								this.$message.success(response.data.msg);
								// 获取配置
								this.SignInConfig();
							} else {
								this.$message.error(response.data.msg);
							}
						} else {
							console.log(response.message);
						}
					}
				);
			},
			// 新增-配置
			submitClick(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.$HTTP.post(
							'riddle/addEcpmConfig', {
								minEcpm: this.ruleForm.minEcpm,
								maxEcpm: this.ruleForm.maxEcpm,
								scaleList: this.ruleForm.scaleList,
								productId: this.productId
							},
							response => {
								if (response.status >= 200 && response.status < 300) {
									if (response.data.code == 0) {
										this.dialogAdd = false;
										this.$message.success(response.data.msg);
										this.resetClick(formName);
										// 获取配置
										this.SignInConfig();
									} else {
										this.$message.error(response.data.msg);
									}
								} else {
									console.log(response.message);
								}
							}
						);
					} else {
						return false;
					}
				});
			},
			// 编辑
			clcedit(row) {
				this.EditruleForm.minEcpm = row.minEcpm;
				this.EditruleForm.maxEcpm = row.maxEcpm;
				this.EditruleForm.scaleList = row.scaleList;
				this.EditruleForm.id = row.id;
				this.dialogEdit = true;
			},
			EditsubmitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.$HTTP.post(
							'riddle/updateEcpmConfig', {
								id: this.EditruleForm.id,
								minEcpm: this.EditruleForm.minEcpm,
								maxEcpm: this.EditruleForm.maxEcpm,
								scaleList: this.EditruleForm.scaleList,
							},
							response => {
								if (response.status >= 200 && response.status < 300) {
									if (response.data.code == 0) {
										this.dialogEdit = false;
										this.$message.success(response.data.msg);
										// 获取配置
										this.SignInConfig();
									} else {
										this.$message.error(response.data.msg);
									}
								} else {
									console.log(response.message);
								}
							}
						);
					} else {
						return false;
					}
				});
			},

			// 分页
			handleSizeChange(val) {
				this.currentPage = 1;
				this.pageSize = val;
				// 获取配置
				this.SignInConfig();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				// 获取配置
				this.SignInConfig();
			}
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.el-input-number {
		width: 100%;
	}

	.readbox {
		display: inline-block;
		margin-left: 20px;

		span {
			margin-right: 10px;
			font-size: 16px;
		}
	}

	.elel {
		display: flex;
		align-items: center;
	}
</style>